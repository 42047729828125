<template>
  <div>
    <title-row> Animal </title-row>
    <ValidationProvider v-slot="{ errors }" rules="required" name="animal name">
      <editable-row
        v-if="(animal && animal.name) || isInEditMode"
        :is-inner-content-visible="isInEditMode"
        label-text="Name:"
        :display-value="animal.name"
        :error="errors[0]"
      >
        <base-input v-model="animal.name" name="animal name" class="text-sm" />
      </editable-row>
    </ValidationProvider>

    <editable-row
      :is-inner-content-visible="isInEditMode"
      label-text="Type:"
      :display-value="animalType && animalType.name ? animalType.name : ''"
    >
      <v-select
        v-model="animalType"
        class="text-sm"
        :options="animalTypesInState"
        :clearable="false"
        :loading="!animalTypesInState.length"
        label="name"
      />
    </editable-row>

    <editable-row
      :is-inner-content-visible="isInEditMode"
      label-text="Breed:"
      :display-value="animal.race || 'Unknown'"
    >
      <base-input v-model="animal.race" name="animal race" class="text-sm" />
    </editable-row>

    <editable-row
      :is-inner-content-visible="isInEditMode"
      label-text="Date of Birth:"
      :display-value="animalBirthDate"
      class="datepicker-wrapper"
    >
      <Datepicker
        v-model="animalBirthDate"
        name="animal birth date"
        input-class="text-sm border border-gray-400 py-2 px-2 leading-tight rounded 
					 w-full focus:outline-none focus:border-gray-600"
        :format="'yyyy-MM-dd'"
        :monday-first="true"
      />
    </editable-row>

    <editable-row
      :is-inner-content-visible="isInEditMode"
      label-text="Gender:"
      :display-value="animalGender"
    >
      <v-select
        v-model="animalGender"
        name="animal gender"
        class="text-sm"
        :options="genderOptionsInState"
        :clearable="false"
        :loading="!genderOptionsInState.length"
        label="name"
      />
    </editable-row>

    <ValidationProvider
      v-slot="{ errors }"
      :rules="{ regex: /^\d+((,|\.){0,1}\d*){0,1}$/ }"
      name="animal weight"
    >
      <editable-row
        :is-inner-content-visible="isInEditMode"
        label-text="Weight:"
        :display-value="getWeightStringFromAppointment(appointment)"
        :error="errors[0]"
        data-testid="animal-weight"
      >
        <div>
          <base-input
            v-model="appointment.weight"
            class="weightInput mr-1 inline-block text-sm"
            :is-inline="true"
            name="animal weight"
          />
          <v-select
            v-model="weightUnit"
            class="weightSelect text-sm"
            :options="weightUnitOptions"
            :loading="!weightUnitOptions.length"
            :clearable="false"
          />
        </div>
      </editable-row>
    </ValidationProvider>

    <editable-row
      v-if="
        (animal.description && animal.description.trim() !== '') || isInEditMode
      "
      :display-value="animal.description"
      label-text="Animal Description:"
      :is-inner-content-visible="isInEditMode"
      :is-label-never-inline="true"
    >
      <textarea-input v-model="animal.description" name="animal description" />
    </editable-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import { getWeightStringFromAppointment } from '@/utils/helpers/formatting-helpers';

const STRING_NO_WEIGHT_UNIT_OPTION = '?';
const STRING_NO_GENDER_OPTION = 'No gender provided';

export default {
  components: { Datepicker },
  props: {
    animal: {
      type: Object,
      default: undefined,
    },
    appointment: {
      type: Object,
      default: undefined,
    },
    isInEditMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('animal', {
      animalGenderInState: 'getGenderName',
      genderOptionsInState: 'getGenderOptions',
      animalTypesInState: 'getAnimalTypes',
    }),
    ...mapGetters('appointment', {
      weightUnitOptionsInState: 'getWeightUnits',
    }),
    animalType: {
      get() {
        return this.animal.animal_type;
      },
      set(value) {
        this.animal.animal_type = value;
        this.animal.type_id = value.id;
      },
    },
    animalBirthDate: {
      get() {
        return this.animal.birthdate;
      },
      set(date) {
        const formattedDate = this.$formatDate(date);
        this.animal.birthdate = formattedDate;
      },
    },
    animalGender: {
      get() {
        const mappedGenderValue = this.genderOptionsInState.find(gender => {
          return gender.value === this.animal.gender;
        });

        return mappedGenderValue
          ? mappedGenderValue.name
          : STRING_NO_GENDER_OPTION;
      },
      set(gender) {
        this.animal.gender = gender.value;
      },
    },
    weightUnit: {
      get() {
        const wUnit = this.appointment.weight_unit
          ? this.appointment.weight_unit.unit
          : this.getWeightUnitOptionByName(null).unit;
        return wUnit;
      },
      set(value) {
        this.appointment.weight_unit = this.getWeightUnitOptionByName(value);
      },
    },
    weightUnitOptions() {
      return [
        STRING_NO_WEIGHT_UNIT_OPTION,
        ...Object.values(this.weightUnitOptionsInState),
      ];
    },
    animalBirthdate() {
      return this.animal.birthdate || 'No date of birth provided';
    },
    animalAge() {
      if (!this.animal.birthdate) {
        return '';
      }
      return this.$getAge(this.animal.birthdate);
    },
  },
  mounted() {
    if (
      !Object.keys(this.weightUnitOptionsInState).length &&
      this.appointment.id
    ) {
      this.getWeightUnits();
    }

    if (!this.animalTypesInState?.length) {
      this.fetchAnimalTypes();
    }
  },
  methods: {
    ...mapActions('appointment', ['getWeightUnits']),
    ...mapActions('animal', ['fetchAnimalTypes']),

    getWeightStringFromAppointment,

    getWeightUnitOptionByName(weightUnitName) {
      const optionsArray = Object.entries(this.weightUnitOptionsInState);
      const nameIndex = 1;
      const idIndex = 0;

      const selectedOptionArray = optionsArray.find(option => {
        return option[nameIndex] === weightUnitName;
      });

      const id = selectedOptionArray
        ? Number.parseInt(selectedOptionArray[idIndex], 10)
        : 0;

      const unit = selectedOptionArray
        ? selectedOptionArray[nameIndex]
        : STRING_NO_WEIGHT_UNIT_OPTION;

      if (unit === STRING_NO_WEIGHT_UNIT_OPTION) {
        console.warn('No default weight unit options have been returned.');
      }

      const selectedOption = { id, unit };

      return selectedOption;
    },
  },
};
</script>

<style scoped lang="postcss">
a {
  @apply underline;
}

.weightSelect {
  display: inline-block;
  width: 120px;
  overflow: visible;
}

.weightSelect ul {
  width: auto;
}

.weightInput {
  width: 50px;
}
</style>

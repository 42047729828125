var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "space-y-6" },
    [
      !_vm.appointment.id && _vm.$route.params.id
        ? _c(
            "div",
            {
              staticClass:
                "mb-3 flex items-center space-x-3 rounded border border-red-200 bg-red-100 p-4 text-red-900",
            },
            [
              _c(
                "span",
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "exclamation-circle" },
                  }),
                ],
                1
              ),
              _c("span", [
                _vm._v("No appointment found with id "),
                _c("span", { staticClass: "font-semibold" }, [
                  _vm._v(_vm._s(_vm.$route.params.id)),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      !_vm.appointment.id && _vm.$route.params.id
        ? _c(
            "base-button",
            {
              staticClass: "mt-1",
              attrs: { size: "sm", color: "light" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v(" Go back to previous page ")]
          )
        : _vm._e(),
      _vm.caseType || _vm.bookingMetaData
        ? _c(
            "span",
            {
              staticClass:
                "inline-block space-y-1 rounded border border-beigeLight bg-beigeLighter p-1 px-2",
            },
            [
              _vm.caseType
                ? _c("div", { staticClass: "flex items-center space-x-3" }, [
                    _c("img", {
                      staticClass: "h-7 w-7",
                      attrs: { src: _vm.caseType.icon_url, alt: "" },
                    }),
                    _c("p", [_vm._v(_vm._s(_vm.caseType.name))]),
                  ])
                : _vm._e(),
              _vm.bookingMetaData
                ? _c("p", [
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v("Internal triage by:"),
                    ]),
                    _vm._v(
                      " " + _vm._s(_vm.bookingMetaData.user.display_name) + " "
                    ),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm.images.length
        ? _c(
            "div",
            [
              _c("title-row", [_vm._v(" Images ")]),
              _c("image-gallery", {
                attrs: {
                  appointment: _vm.appointmentModel,
                  images: _vm.images,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.appointment.description && _vm.appointment.description.trim() !== ""
        ? _c(
            "div",
            [
              _c("title-row", [_vm._v(" Provided Information ")]),
              _c("display-row", [
                _vm._v(" " + _vm._s(_vm.appointment.description) + " "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.animal && _vm.animal.id
        ? _c(
            "div",
            { staticClass: "relative float-right inline-block cursor-pointer" },
            [
              _c(
                "button",
                {
                  attrs: { "data-testid": "edit-animal-details" },
                  on: { click: _vm.toggleEditMode },
                },
                [
                  !_vm.isInEditMode && _vm.isEditingEnabled
                    ? _c("fv-icon", { attrs: { icon: "edit-alt" } })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("ValidationObserver", {
        attrs: { tag: "form" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.onSubmit)
                      },
                    },
                  },
                  [
                    _vm.animal && _vm.animal.id
                      ? _c("animal-section", {
                          key: _vm.animalSectionKey,
                          ref: "animalSection",
                          class: _vm.isLoading ? "opacity-50" : "",
                          attrs: {
                            animal: _vm.animalModel,
                            appointment: _vm.appointmentModel,
                            "is-in-edit-mode": _vm.isInEditMode,
                          },
                        })
                      : _vm._e(),
                    _vm.isInEditMode
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "flex w-full justify-end space-x-2 py-4",
                          },
                          [
                            _c(
                              "base-button",
                              {
                                attrs: {
                                  type: "button",
                                  color: "cancel",
                                  disabled: _vm.isLoading,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.toggleEditMode.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                            _c(
                              "base-button",
                              {
                                attrs: {
                                  type: "submit",
                                  color: "primary",
                                  loading: _vm.isLoading,
                                  "data-testid": "save-button",
                                },
                              },
                              [_vm._v(" Save ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("form"),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm.appointment && _vm.appointment.id
        ? _c("customer-section", {
            attrs: {
              animal: _vm.animalModel,
              appointment: _vm.appointmentModel,
            },
          })
        : _vm._e(),
      _vm.appointment && _vm.appointment.id
        ? _c("booking-section", {
            attrs: { appointment: _vm.appointmentModel },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }